const array = {
  // Sort an array
  sort(a, b, prop) {
    if (a[prop] < b[prop]) {
      return -1;
    }
    if (a[prop] > b[prop]) {
      return 1;
    }
    return 0;
  },
  // Returns if a value is an array
  isArray(value) {
    return value && typeof value === 'object' && value.constructor === Array;
  }
};

export default array;
