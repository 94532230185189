import { body } from '../dom-elements'
import keyboardEventCode from '../keyboard-event-code'

const KEYBOARD_FOCUSED = 'has--keyboard-focus'

class DetectKeyboardFocus {
  constructor() {
    this.keyDown = false

    document.addEventListener('keydown', (event) => this.handleKey(true, event), true)
    document.addEventListener('keyup', () => this.handleKey(false), true)
    document.addEventListener('mouseleave', () => this.handleKey(false))
    document.addEventListener('focus', () => this.handleFocus(), true)
    document.addEventListener('blur', () => DetectKeyboardFocus.handleBlur(), true)
  }

  handleKey(pressed, event) {
    const key = event ? event.key || event.keyCode : undefined
    if (event && keyboardEventCode.tab.indexOf(key) === -1) return

    this.keyDown = pressed
  }

  handleFocus() {
    if (this.keyDown) body.classList.add(KEYBOARD_FOCUSED)
  }

  static handleBlur() {
    body.classList.remove(KEYBOARD_FOCUSED)
  }
}

export default new DetectKeyboardFocus()
