/**
 * Finds the closets focusable target
 * @param focus element
 * @return { Object }
 */
const findClosestFocusTarget = (el) => {
  const elements = el.querySelectorAll(
    'a:not([tabindex="-1"]), area:not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]):not([type="hidden"]), select:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]):not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), iframe:not([tabindex="-1"]), .a-tooltip:not([tabindex="-1"])',
  );
  return elements.length ? elements[0] : el;
};

export default findClosestFocusTarget;
