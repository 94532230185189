import { body } from '@utilities/dom-elements'
import RafThrottle from '@utilities/raf-throttle'
import cssVars from 'css-vars-ponyfill'
import isIos from '@utilities/detect-os'

const HOOK_HEADER = '[js-hook-header]'
const HOOK_HEADER_CONTAINER = '[js-hook-header-container]'
const HOOK_PRODUCT_ADD_TO_CART = '[js-hook-product-add-to-cart]'
const HOOK_HEADER_HERO = '[js-hook-header-hero]'

class PageCssVariables {
  constructor() {
    this.container = [...document.querySelectorAll('.o-container')].find(
      (container) => container.scrollWidth > 0,
    ) // find first available container that has dimensions
    this.header = document.querySelector(HOOK_HEADER)
    this.headerContainer = this.header?.querySelector(HOOK_HEADER_CONTAINER)
    this.productAddToCart = document.querySelector(HOOK_PRODUCT_ADD_TO_CART)
    this.headerHero = document.querySelector(HOOK_HEADER_HERO)

    if (this.headerHero !== null) {
      this.headerHeight = this.header?.offsetHeight + this.headerHero?.offsetHeight;
    } else {
      this.headerHeight = this.header?.offsetHeight;
    }

    this.bindEvents()
    this.setInitialPageCssVariables()
  }

  bindEvents() {
    RafThrottle.set([
      {
        element: window,
        event: 'resize',
        namespace: 'PageCssVariables',
        fn: () => {
          this.updatePageCssVariables()
        },
        delay: 200,
      },
    ])

    if (this.header) {
      const observer = new MutationObserver(() => {
        this.updatePageCssVariables()
      })

      // Observe changes in header properties and that of it's children
      observer.observe(this.header, {
        attributes: true,
        attributeFilter: ['class'],
        childList: true,
        subtree: true,
      })
    }
  }

  setInitialPageCssVariables() {
    const ch = isIos ? window.innerHeight : window.screen?.height || window.outerHeight
    const vh = ch * 0.01

    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--header-hero-height', `${this.headerHero?.offsetHeight || 0}px`)
    document.documentElement.style.setProperty('--header-height', `${this.headerHeight || 0}px`)
    document.documentElement.style.setProperty('--header-container-height', `${this.headerContainer?.offsetHeight || 0}px`)
    document.documentElement.style.setProperty('--container-width', `${this.container?.scrollWidth || body.scrollWidth}px`)
    document.documentElement.style.setProperty('--product-add-to-cart-height', `${this.productAddToCart?.offsetHeight || 0}px`)


    cssVars({
      variables: {
        '--header-hero-height': `${this.headerHero?.offsetHeight || 0}px`,
        '--header-height': `${this.headerHeight || 0}px`,
        '--header-container-height': `${this.headerContainer?.offsetHeight || 0}px`,
        '--container-width': `${this.container?.scrollWidth || body.scrollWidth}px`,
        '--product-add-to-cart-height': 0,
        '--scroll': 0,
        '--vh': `${vh}px`,
      }
    })

    this.updatePageCssVariables()
  }

  updatePageCssVariables() {
    document.documentElement.style.setProperty('--header-hero-height', `${this.headerHero?.offsetHeight || 0}px`)
    document.documentElement.style.setProperty('--header-height', `${this.headerHeight || 0}px`)
    document.documentElement.style.setProperty('--header-container-height', `${this.headerContainer?.offsetHeight || 0}px`)
    document.documentElement.style.setProperty('--container-width', `${this.container?.scrollWidth || body.scrollWidth}px`)
    document.documentElement.style.setProperty('--product-add-to-cart-height', `${this.productAddToCart?.offsetHeight || 0}px`)

    cssVars({
      variables: {
        '--header-hero-height': `${this.headerHero?.offsetHeight || 0}px`,
        '--header-height': `${this.headerHeight || 0}px`,
        '--header-container-height': `${this.headerContainer?.offsetHeight || 0}px`,
        '--container-width': `${this.container?.scrollWidth || body.scrollWidth}px`,
        '--product-add-to-cart-height': `${this.productAddToCart?.offsetHeight || 0}px`,
        '--scroll': 0
      }
    })
  }
}

export default new PageCssVariables()
