import RafThrottle from './raf-throttle';
import DetectTouch from './detect-touch';
import Events from './events';

const DRAG_CLASS = 'is--dragging';
const DRAGGABLE_CLASS = 'is--draggable';

let startPositionX;
let startScrollX;

let isDragging = false;

class Drag {
  constructor(element) {
    if (DetectTouch.isTouchDevice) return;

    this.element = element;
    this.elementId = this.element.getAttribute('data-drag-id');
    this.children = [...this.element.querySelectorAll('a, button, input')];

    this.startListener = e => this.startDrag(e);
    this.moveListener = e => this.moveDrag(e);
    this.stopListener = e => this.stopDrag(e);

    this.childClickListener = e => Drag.childClick(e);

    this.bindEvents();
  }

  bindEvents() {
    this.bindDragEvents();

    Events.$on(`drag[${this.elementId}]::update`, () => this.bindDragEvents());

    RafThrottle.set([
      {
        element: window,
        event: 'resize',
        namespace: `dragResize[${this.elementId}]`,
        fn: () => this.bindDragEvents(),
        delay: 500
      }
    ]);
  }

  bindDragEvents() {
    if (this.element.scrollWidth > this.element.offsetWidth) {
      this.children.map(child => { child.addEventListener('click', this.childClickListener)});

      this.element.classList.add(DRAGGABLE_CLASS);
      this.element.addEventListener('mousedown', this.startListener);
    } else {
      this.children.map(child => {child.removeEventListener('click', this.childClickListener)});

      this.element.classList.remove(DRAGGABLE_CLASS);
      this.element.removeEventListener('mousedown', this.startListener);
    }
  }

  startDrag(e) {
    e.preventDefault();

    startPositionX = e.pageX;
    startScrollX = this.element.scrollLeft;

    this.element.classList.add(DRAG_CLASS);

    window.addEventListener('mousemove', this.moveListener);
    window.addEventListener('dragover', this.moveListener);

    window.addEventListener('mouseup', this.stopListener);
    window.addEventListener('dragend', this.stopListener);
  }

  moveDrag(e) {
    e.preventDefault();

    if (e.pageX === 0) return;

    const positionDiff = startPositionX - e.pageX;

    this.element.scrollLeft = startScrollX + positionDiff;

    if (positionDiff !== 0) isDragging = true;
  }

  stopDrag(e) {
    e.preventDefault();

    this.element.classList.remove(DRAG_CLASS);

    window.removeEventListener('mousemove', this.moveListener);
    window.removeEventListener('drag', this.moveListener);

    window.removeEventListener('dragend', this.stopListener);
    window.removeEventListener('mouseup', this.stopListener);

    setTimeout(() => {
      isDragging = false;
    }, 100);
  }

  static childClick(e) {
    if (isDragging) e.preventDefault();
  }
}

export default Drag;
