import Events from "@utilities/events";

class GTM {
  constructor() {
    GTM.bindEvents();
  }

  static bindEvents() {
    Events.$on("gtm::push", (event, data) => GTM.push(data));
  }

  static push(data) {
    if(data) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    }
  }
}

export default new GTM();
