import Events from '@utilities/events'
import { body } from '@utilities/dom-elements'

const LOADING_INDICATOR_HOOK = 'js-hook-loader'
const PREFIX_CLASS = 'loader--'
const FIXED_POSITION_CLASS = 'loader--fixed'

class Loader {
  constructor() {
    this.active = false

    this.bindEvents()
  }

  /**
   * Bind events
   */
  bindEvents() {
    Events.$on('loader::show', (e, data) => this.showLoader(data))
    Events.$on('loader::hide', () => this.hideLoader())
  }

  /**
   * Build the template fragment which gets appended to the DOM
   * @param theme (string) - set variant for theming of loader
   * @param target (string, selector) - Pass a selector, to append the loader to a different element
   * @param size (string) - set variant for size of loader
   * @returns {DocumentFragment}
   */
  static buildTemplateFragment(theme, target, size) {
    const themeClass = theme ? ` ${PREFIX_CLASS}${theme}` : ''
    const fixedClass = !target ? ` ${FIXED_POSITION_CLASS}` : ''
    const sizeClass = size ? ` loader--${size}` : ''

    return document.createRange().createContextualFragment(`
      <div class="c-loader${themeClass}${fixedClass}${sizeClass}" ${LOADING_INDICATOR_HOOK}>
        <div class="loader__spinner"></div>
      </div>
    `)
  }

  /**
   * Shows the loader
   * @param options
   * - darkTheme (boolean) - Set to true for dark theme
   * - target (string, selector) - Pass a selector debounceChan, to append the loader to a different element
   */
  showLoader(options) {
    if (this.active) return
    this.active = true

    const { theme, target, size } = options || {}
    const LoaderTemplate = Loader.buildTemplateFragment(theme, target, size)

    if (target) {
      const targetEl = target instanceof Element ? target : document.querySelector(target)
      targetEl.appendChild(LoaderTemplate)
    } else {
      body.appendChild(LoaderTemplate)
    }
  }

  /**
   * Hides the loader
   */
  hideLoader() {
    if (!this.active) return

    const loader = document.querySelector(`[${LOADING_INDICATOR_HOOK}]`)
    if (loader) loader.remove()
    this.active = false
  }
}

export default new Loader()
