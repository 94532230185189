/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-multi-assign */
/* eslint-disable no-restricted-globals */
import Events from '@utilities/events'

class History {
  constructor() {
    prepareHistoryEvents()
    History.bindEvents()
  }


  /**
   * Bind events
   */
  static bindEvents() {
    Events.$on('history::push', (e, data) => History.pushHistory(data));
    Events.$on('history::replace', (e, data) => History.replaceHistory(data));
  }

  /**
   * Create a new URL entry in your History
   * @param {Object} data
   * @param {Object} data[].state State object
   * @param {string} data[].url New url
   */
  static pushHistory(data) {
    const pushOptions = {
      state: data.state || {},
      url: data.url
    };

    window.history.pushState(pushOptions.state, document.title, pushOptions.url);
  }

  /**
   * Overwrite current URL entry in your History
   * @param {Object} data
   * @param {Object} data[].state State object
   * @param {string} data[].url New url
   */
  static replaceHistory(data) {
    const replaceOptions = {
      state: data.state || {},
      url: data.url
    };

    window.history.replaceState(replaceOptions.state, document.title, replaceOptions.url);
  }

}

/**
 * Define the events where we are adding a callback to
 */
function prepareHistoryEvents() {
  const events = [
    {
      eventName: 'pushState',
      callbackEventName: 'onpushstate'
    },
    {
      eventName: 'replaceState',
      callbackEventName: 'onreplacestate'
    }
  ];

  events.forEach(obj => addHistoryCallbackEvent(obj));

  // Add callback to all events
  // eslint-disable-next-line
  window.onpopstate = window.history.onreplacestate = window.history.onpushstate = state => Events.$trigger('history::update', { data: { state } });
}

/**
 * Define the events that will get a callback
 * @param {Object} obj
 * @param {string} obj[].eventName Name of history event
 * @param {string} obj[].callbackEventName Name of callback
 */
function addHistoryCallbackEvent(obj) {
  const historyEvent = window.history[obj.eventName];

  window.history[obj.eventName] = function(state) {
    if (typeof window.history[obj.callbackEventName] === 'function') {
      window.history[obj.callbackEventName]({ state });
    }

    // eslint-disable-next-line
    return historyEvent.apply(window.history, arguments);
  };
}

export default new History()
