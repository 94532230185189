/**
 * Initializes the application.
 *
 * Sets the publicPath for Webpack and provides the loadChunks function to load the needed client side scripts.
 */
import '@utilities/environment'
import { body } from '@utilities/dom-elements'
import { initServiceWorker, removeServiceWorker } from '@utilities/service-worker'
import loadChunksVal from './loadChunks.val'
import appConfig from './appConfig'

const { basePath } = appConfig

// set public path if needed. If webpack-dev-server is running this has a value assigned.
// eslint-disable-next-line camelcase
if (!__webpack_public_path__ && basePath) {
  // eslint-disable-next-line camelcase
  __webpack_public_path__ = basePath
}

/**
 * Loads the needed scripts provided via the additionalScripts config.
 */
// eslint-disable-next-line import/prefer-default-export
export function loadChunks() {
  const { additionalScripts } = appConfig
  if (additionalScripts) {
    loadChunksVal(additionalScripts)
  }
}

/**
 * Init Serviceworker
 */
// eslint-disable-next-line import/prefer-default-export
export function loadServiceWorker() {
  if (body.dataset.serviceWorkerEnabled) {
    initServiceWorker()
  } else {
    removeServiceWorker()
  }
}
