import raf from 'raf';
import Events from '@utilities/events';

const ST_HOOK = 'a[href^="#"]';
const ST_DURATION = 500;
const ST_OFFSET = 160;

class ScrollTo {
  /* eslint-disable class-methods-use-this */
  constructor() {
    this.bindEvents();
    this.initElements();
    this.checkUrlHash();
  }

  /**
   * Bind event
   */
  bindEvents() {
    Events.$on('scroll-to::scroll', (event, data) => {
      const {target, scrollElement, offset, duration} = data;
      this.scrollTo(target, duration, offset, scrollElement);
    });

    Events.$on('scroll-to::reinit-elements', (event, data) => this.initElements(data));
  }

  initElements() {
    this.elements = getElements();

    Array.from(this.elements).forEach((element) => {
      if (element.scrollToisInitialised) {
        return;
      }

      element.addEventListener('click', (event) => {
        const target = element.getAttribute('href').split('#');
        const targetEl = target[1] !== '' ? document.querySelector(`#${target[1]}`) : false;

        if (targetEl) {
          event.preventDefault();
          const targetIsAccordionItem = targetEl.getAttribute('js-hook-accordion-content');

          const scrollConfig = {
            position: targetEl.getBoundingClientRect(),
            duration: element.dataset.scrollDuration
              ? parseInt(element.dataset.scrollDuration, 10)
              : ST_DURATION,
            offset: element.dataset.scrollOffset
              ? parseInt(element.dataset.scrollOffset, 10)
              : ST_OFFSET,
            scrollElement: element.dataset.scrollElement,
          };

          if (targetIsAccordionItem !== null) {
            Events.$trigger(`accordion[${target[1]}]::open`);
          }

          scrollTo(scrollConfig);

          setTimeout(() => {
            if (document.activeElement === targetEl) return;
            targetEl.focus();
          }, 10);
        }
      });

      element.scrollToisInitialised = true;
    });
  }

  scrollTo(target, duration, offset, scrollElement) {
    const scrollConfig = {
      position: target.getBoundingClientRect(),
      duration: typeof duration !== 'undefined' ? parseInt(duration, 10) : ST_DURATION,
      offset: typeof offset !== 'undefined' ? parseInt(offset, 10) : ST_OFFSET,
      scrollElement,
    };

    return scrollTo(scrollConfig);
  }

  checkUrlHash() {
    const hash = window.location.href.split('#')[1];
    const targetEl = hash ? document.querySelector(`#${hash}`) : null;

    if (targetEl) {
      const targetIsAccordionItem = targetEl.getAttribute('js-hook-accordion-content');

      if (targetIsAccordionItem !== null) {
        Events.$trigger(`accordion[${hash}]::open`);
      }

      setTimeout(() => {
        const scrollConfig = {
          position: targetEl.getBoundingClientRect(),
          duration: ST_DURATION,
          offset: ST_OFFSET,
        };

        scrollTo(scrollConfig);
      }, 2000);
    }
  }
}

/**
 * Gets all elements matching the ST_HOOK
 * @returns {NodeList} All matching HTMLElements
 */
function getElements() {
  return document.querySelectorAll(ST_HOOK);
}

/**
 * Scrolls the window to the top
 */
function scrollTo({position, duration, offset, scrollElement}) {
  return new Promise((resolve) => {
    const scrollPositionY = scrollElement
      ? scrollElement.scrollTop
      : window.scrollY || window.pageYOffset
    const scrollPositionX = scrollElement
      ? scrollElement.scrollLeft
      : window.scrollX || window.pageXOffset;

    const toY = parseInt((position.top + scrollPositionY - offset).toFixed(0), 10);
    const toX = parseInt((position.left + scrollPositionX - offset).toFixed(0), 10);

    const startY = scrollElement
      ? scrollElement.scrollTop
      : Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    const startX = scrollElement
      ? scrollElement.scrollLeft
      : Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);

    const changeY = toY - startY;
    const changeX = toX - startX;

    let currentTime = 0;
    const increment = 10;

    const directionY = toY > startY ? 1 : 0;
    const directionX = toX > startX ? 1 : 0;

    const animate = () => {
      currentTime += increment;
      const valY = parseInt(easeInOutQuad(currentTime, startY, changeY, duration).toFixed(0), 10);
      const valX = parseInt(easeInOutQuad(currentTime, startX, changeX, duration).toFixed(0), 10);

      if (scrollElement) {
        scrollElement.scrollTop = valY;
        scrollElement.scrollLeft = valX;
      } else {
        document.body.scrollTop = valY;
        document.documentElement.scrollTop = valY;

        document.body.scrollLeft = valX;
        document.documentElement.scrollLeft = valX;
      }

      if (((valY >= toY && directionY === 1) || (valY <= toY && directionY === 0)) && ((valX >= toX && directionX === 1) || (valX <= toX && directionX === 0))) {
        resolve();
      } else {
        raf(animate);
      }
    }

    animate();
  });
}

function easeInOutQuad(t, b, c, d) {
  t /= d / 2 // eslint-disable-line
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t-- // eslint-disable-line
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

export default new ScrollTo();
