const keyboardEventCode = {
  enter: ['Enter', 13],
  escape: ['Escape', 'Esc', 27],
  space: ['Space', ' ', 32],
  tab: ['Tab', 9],
  backspace: ['Backspace', 8],
  end: ['End', 35],
  up: ['Up', 38],
  down: ['Down', 40],
  home: ['Home', 36],
  option: ['Alt', 18],
  slash: ['Slash', '/', 191],
  optionSlash: ['÷'],
}

export default keyboardEventCode
