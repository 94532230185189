/* eslint-disable */
class ModuleInit {
  async(selector, moduleName, optArguments) {
    const elements = this.findElements(selector)
    if (elements.length) {
      moduleName().then((constructor) => {
        Array.from(this.findElements(selector)).forEach((element) => {
          this.loadConstructor(element, constructor.default, optArguments)
        })
      })
    }
  }

  sync(selector, constructor, optArguments) {
    Array.from(this.findElements(selector)).forEach((element) =>
      this.loadConstructor(element, constructor, optArguments),
    )
  }

  loadConstructor(element, constructor, optArguments) {
    element._initializedModules = element._initializedModules || []

    const forceInit = optArguments && optArguments.force;

    if (!constructor) console.info(`Constructor is not found for element`, element);

    if (forceInit || (constructor && element._initializedModules.indexOf(constructor.name) === -1)) {
      element._initializedModules.push(constructor.name)

      if (!optArguments) {
        if (typeof constructor === 'object') {
          return constructor
        }
        return new constructor(element)
      }
      return new constructor(element, optArguments);
      // const constructorArguments = [null, element]
      // Array.prototype.push.apply(constructorArguments, optArguments)
      // return new (constructor.bind(...constructorArguments))()
    }
  }

  findElements(selector) {
    return document.querySelectorAll(selector)
  }
}

// IE polyfill for constructor.name
;(function () {
  if (Function.prototype.name === undefined && Object.defineProperty !== undefined) {
    Object.defineProperty(Function.prototype, 'name', {
      get() {
        const funcNameRegex = /function\s([^(]{1,})\(/
        const results = funcNameRegex.exec(this.toString())
        return results && results.length > 1 ? results[1].trim() : ''
      },
      set() {
        // Empty function to prevent set is not a function
      },
    })
  }
})()

// Export the module init function
export default new ModuleInit()
