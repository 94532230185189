import { html } from '@utilities/dom-elements'
import API from '@utilities/api'
import Events from '@utilities/events'

class InitJs {
  constructor() {
    // Replace no-js class with js class
    html.className = html.className.replace(/(^|\s)no-js(\s|$)/, '$1js$2')

    // Set global csrf token for each api call
    InitJs.setAntiForgeryToken()
    InitJs.setDocumentTouchListener()
    InitJs.checkGoogleMapsApi();
  }

  static setAntiForgeryToken() {
    const csrfToken = document.querySelector('[js-hook-csrf-token]')
    if (!csrfToken) return
    API.setAntiForgeryToken(csrfToken.name, csrfToken.value)
  }

  static setDocumentTouchListener() {
    document.addEventListener('touchstart', (event) =>
      Events.$trigger('document::touched', { data: event }),
    )
  }

  static checkGoogleMapsApi() {
    window.googleMapsCallback = () => {
      if(!this.mapsApiLoaded) {
        Events.$trigger('google-maps-api::loaded');
        this.mapsApiLoaded = true;
      }
    };
  }
}

export default new InitJs()
