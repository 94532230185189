/**
 * Get the storefront config provided via script tag.
 *
 * Depending on the configuration it uses Redux or read it directly
 */
let { app } = window.deptsfra || {}
if (!app) {
  app = {}
}
const appConfig = app
export default appConfig
