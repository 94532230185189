/**
 * @shelf-version: 1.0.0
 */
import Events from '@utilities/events'
import axios from 'axios'
import queryString from 'query-string'
import Environment from '../environment'

const base = '' || `${window.location.protocol}//${window.location.host}`
const endpointBase = {
  local: base,
  test: base,
  acceptation: base,
  production: base,
  json: '../data/api/',
}

class API {
  constructor() {
    addInterceptors()
  }

  /* eslint-disable class-methods-use-this */
  /**
   * Set an anti forgery token to make AJAX requests to the backend
   * @param {string} name
   * @param {string} value
   */
  setAntiForgeryToken(name, value) {
    this.antiForgeryToken = {}
    this.antiForgeryToken = {
      name,
      value,
    }
  }

  /**
   * Overwrite default enpoints
   * @param {string} environment
   * @param {string} endpoint
   */
  setEndpointBase(environment, endpoint) {
    if (endpointBase[environment]) {
      endpointBase[environment] = endpoint
    }
  }

  get(path, data = {}, json, options = {}) {
    let config = {
      url: getEndpoint(path, json, 'get'),
      params: data,
      method: getMethod('GET', json),
    }

    config.headers = {}
    config.headers['x-requested-with'] = 'XMLHttpRequest'

    config = Object.assign({}, config, options)

    return axios(config)
  }

  post(path, data = {}, json, options = {}) {
    let config = {
      url: getEndpoint(path, json, 'post'),
      data,
      method: getMethod('POST', json),
    }

    config.headers = {}
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'

    if (this.antiForgeryToken) {
      config.data[this.antiForgeryToken.name] = this.antiForgeryToken.value
    }

    config.data = queryString.stringify(config.data)

    config = Object.assign({}, config, options)

    return axios(config)
  }

  put(path, data = {}, json, options = {}) {
    let config = {
      url: getEndpoint(path, json, 'put'),
      data,
      method: getMethod('PUT', json),
    }

    config = Object.assign({}, config, options)

    return axios(config)
  }

  delete(path, data = {}, json, options = {}) {
    let config = {
      url: getEndpoint(path, json, 'delete'),
      data,
      method: getMethod('DELETE', json),
    }

    config = Object.assign({}, config, options)

    return axios(config)
  }
}

/**
 * Get the endpoint. If we require json we will return a json file.
 * @param {string} path
 * @param {string|boolean} json
 * @param {string} method
 */
function getEndpoint(path, json, method) {
  if (path.substr(0, 2) === '//' || path.substr(0, 4) === 'http' || path.substr(0, 1) === '?') {
    return path
  }

  if (json === true || (json === 'local' && Environment.isLocal)) {
    return `${endpointBase.json + path}--${method}.json`
  }

  return endpointBase[Environment.get] + path
}

/**
 * Will transform the method to GET if we require json file
 * @param {string} method Given method to check for transformation
 * @param {string|boolean} json To check if we need to transform the method
 */
function getMethod(method, json) {
  return json === true || (json === 'local' && Environment.isLocal) ? 'GET' : method
}

function addInterceptors() {
  axios.interceptors.response.use(
    (response) => {
      const { gtmEvents, error, serverErrors, errorMessage } = response.data

      if (gtmEvents) Events.$trigger('gtm::eventFromBackend', { data: gtmEvents })

      if (error) {
        let errorMessages = []
        if (serverErrors && serverErrors.length > 0) errorMessages = serverErrors
        if (errorMessage && errorMessage.length > 0) errorMessages = [errorMessage]
        errorMessages.forEach((message) =>
          Events.$trigger('gtm::errorEvent', { data: { errorMessage: message } }),
        )
      }

      return response
    },
    (error) => {
      if (error) {
        return Promise.reject(error)
      }
      return false
    },
  )
}
export default new API()
