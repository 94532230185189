import cookies from 'js-cookie';
import Events from '@utilities/events';
import API from '@utilities/api';
import { body } from '@utilities/dom-elements';
import setTabIndexOfChildren from '@utilities/set-tabindex-of-children';

const COOKIE_BAR_HOOK = '[js-hook-cookie-bar]';
const COOKIE_OPTIONS_BUTTON_HOOK = '[js-hook-cookie-settings-button]';
const SHOW_CLASS = 'cookie-bar--is-visible';
const SF_TRACKING_CONSENT_HOOK = '[js-hook-sf-tracking-consent]';

const COOKIE_FORM_HOOK = '[js-hook-cookies-form]';
const COOKIE_FORM_SUBMIT_HOOK = '[js-hook-cookies-form-accept]';
const COOKIE_FORM_ACCEPT_ALL_HOOK = '[js-hook-cookies-form-accept-all]';
const COOKIE_OPTION_HOOK = '[js-hook-cookies-option]';

const COOKIEBAR_COOKIE_NAME = 'accepted';
const COOKIEBAR_COOKIE_VERSION = 'version';

const COOKIE_DEFAULT_VALUE = '1';
const COOKIE_DECLINED_VALUE = '0';

class Cookies {
  constructor() {
    this.cookiebar = document.querySelector(COOKIE_BAR_HOOK);

    // early return
    if (!this.cookiebar) return;

    this.cookiebotEnabled = this.cookiebar.dataset.cookiebotEnabled;
    this.cookiePrefix = this.cookiebar.dataset.cookiePrefix;
    this.cookiebarOptionsButton = document.querySelector(COOKIE_OPTIONS_BUTTON_HOOK);

    this.cookiebarVersion = this.cookiebar.dataset.policyVersion || '1';

    this.sfTrackingConsent = document.querySelector(SF_TRACKING_CONSENT_HOOK);

    this.sFTrackingConsentAcceptUrl = this.sfTrackingConsent?.getAttribute('data-accept');
    this.sFTrackingConsentRejectUrl = this.sfTrackingConsent?.getAttribute('data-reject');
    this.sFTrackingConsentRevokeMessage = this.sfTrackingConsent?.getAttribute(
      'data-revoke-message',
    );

    if (this.sfTrackingConsent) {
      this.sfTrackingConsentGiven = this.sfTrackingConsent?.getAttribute('data-is-consented');
      this.sfTrackingApiUsed = this.sfTrackingConsent?.getAttribute('data-use-api');
    }

    this.form = {
      element: document.querySelector(COOKIE_FORM_HOOK),
    };

    if (this.form.element) {
      this.form.url = this.form.element.getAttribute('action');
      this.form.options = [...this.form.element.querySelectorAll(COOKIE_OPTION_HOOK)];
      this.form.submit = this.form.element.querySelector(COOKIE_FORM_SUBMIT_HOOK);
      this.form.acceptAll = this.form.element.querySelector(COOKIE_FORM_ACCEPT_ALL_HOOK);
      this.form.redirectUrl = this.form.element.dataset.redirectUrl
    }

    this.hostname = window.location.hostname;
    this.host = this.hostname.split('.');

    this.cookieName = {
      functional: 'functional',
      analytics: 'analytics',
      social: 'social',
      advertising: 'advertising',
      other: 'other',
    };

    this.config = {
      version: this.cookiebarVersion,
      domain: `${this.host[this.host.length - 2]}.${this.host[this.host.length - 1]}`,
      cookies: [
        {
          name: this.cookieName.functional,
          default: COOKIE_DEFAULT_VALUE,
        },
        {
          name: this.cookieName.analytics,
          default: COOKIE_DEFAULT_VALUE,
        },
        {
          name: this.cookieName.social,
          default: COOKIE_DECLINED_VALUE,
        },
        {
          name: this.cookieName.advertising,
          default: COOKIE_DECLINED_VALUE,
        },
        {
          name: this.cookieName.other,
          default: COOKIE_DECLINED_VALUE,
        },
      ],
    };

    this.init();
  }

  /**
   * Gets cookie with given value
   * @param name
   * @returns {*}
   */
  getCookie(name) {
    return cookies.get(this.prefixCookieName(name));
  }

  /**
   * Prefix cookie with project prefix name
   * @param name
   * @returns {string}
   */
  prefixCookieName(name) {
    return `${this.cookiePrefix}-${name}`;
  }

  /**
   * Init initial methods to check for invalid cookie, setting default cookies and checking the SF consent tracking cookies
   */
  init() {
    if (this.getCookie(COOKIEBAR_COOKIE_VERSION) !== this.config.version) {
      this.removeInvalidatedCookies();
    }

    this.setDefaultCookies();
    this.bindEvents();

    if (this.form.element) {
      this.prefillFormCookies();
    }

    if (!this.getCookie(COOKIEBAR_COOKIE_NAME)) {
      this.setCookie(COOKIEBAR_COOKIE_NAME, COOKIE_DECLINED_VALUE);
    }

    if (this.cookiebotEnabled === 'true') {
      this.cookiebotCookies();
    } else if (
      (this.getCookie(COOKIEBAR_COOKIE_VERSION) !== this.config.version && !this.form.element) ||
      (this.getCookie(COOKIEBAR_COOKIE_NAME) === COOKIE_DECLINED_VALUE && !this.form.element)
    ) {
      this.show();
    }

    this.checkForSalesforceSessionCookie();
  }

  /**
   * Consent to Salesforce tracking through API endpoint, only on marketing cookies
   */
  checkForSalesforceSessionCookie() {
    if (!this.sFTrackingConsentAcceptUrl || this.sFTrackingConsentRejectUrl) return;

    if (
      this.sfTrackingApiUsed === 'true' &&
      this.getCookie(this.cookieName.advertising) === COOKIE_DEFAULT_VALUE &&
      this.sfTrackingConsentGiven !== 'true'
    ) {
      API.get(this.sFTrackingConsentAcceptUrl);
    }
  }

  /**
   * Bind all events
   */
  bindEvents() {
    if (this.cookiebar) {
      Events.$on('cookies::dismiss', () => this.acceptAllCookies());
      Events.$on('cookies::decline', () => this.declineAllCookies());
    }

    if (this.form.element) {
      Events.$on('cookies::preferences-default', () => this.setDefaultPreferences());
      this.form.acceptAll.addEventListener('click', () => this.acceptAllCookies());
      this.form.element.addEventListener('submit', (event) => this.submitFormCookies(event));
    }
  }

  /**
   * Set default cookies based on config object
   */
  setDefaultCookies() {
    this.config.cookies.forEach((cookie) => {
      if (!this.getCookie(cookie.name) && cookie.default === COOKIE_DEFAULT_VALUE) {
        this.setCookie(cookie.name, cookie.default);
      }
    });
  }

  /**
   *
   * @param noRefresh {Boolean}
   */
  acceptAllCookies(noRefresh) {
    const acceptedCookies = {};

    this.config.cookies.forEach((cookie) => {
      this.setCookie(cookie.name, COOKIE_DEFAULT_VALUE);
      acceptedCookies[this.prefixCookieName(cookie.name)] = COOKIE_DEFAULT_VALUE;
    });

    if (this.sfTrackingApiUsed === 'true') {
      Events.$trigger('loader::show', {
        data: {
          target: COOKIE_BAR_HOOK,
        },
      });
      API.get(this.sFTrackingConsentAcceptUrl).finally(() => {
        Events.$trigger('loader::hide');

        this.addGlobalCookies(acceptedCookies);
        this.hide();

        if (noRefresh) return;

        if(this.form.redirectUrl) {
          window.location = this.form.redirectUrl;
        } else {
          window.location.reload();
        }
      });

      return;
    }

    this.addGlobalCookies(acceptedCookies);

    if (noRefresh) return;

    if(this.form.redirectUrl) {
      window.location = this.form.redirectUrl;
    } else {
      window.location.reload();
    }
  }

  declineAllCookies(noRefresh) {
    const declinedCookies = {};

    this.config.cookies.forEach((cookie) => {
      this.setCookie(cookie.name, cookie.default);
      declinedCookies[this.prefixCookieName(cookie.name)] = COOKIE_DEFAULT_VALUE;
    });

    if (this.sfTrackingApiUsed === 'true') {
      Events.$trigger('loader::show', {
        data: {
          target: COOKIE_BAR_HOOK,
        },
      });
      API.get(this.sFTrackingConsentAcceptUrl).finally(() => {
        Events.$trigger('loader::hide');

        this.addGlobalCookies(declinedCookies);
        this.hide();

        if (noRefresh) return;

        if(this.form.redirectUrl) {
          window.location = this.form.redirectUrl;
        } else {
          window.location.reload();
        }
      });

      return;
    }

    this.addGlobalCookies(declinedCookies);

    if (noRefresh) return;

    if(this.form.redirectUrl) {
      window.location = this.form.redirectUrl;
    } else {
      window.location.reload();
    }
  }

  // Sets cookies when cookiebot is enabled
  cookiebotCookies() {
    const cookiebot = window.Cookiebot;

    if (cookiebot) {
      window.addEventListener('CookiebotOnLoad', () => {
        const cookiebotAcceptedCookies = {};
        const cookiebotAcceptedCookiesList = [];

        if (cookiebot.consent.necessary) {
          cookiebotAcceptedCookiesList.push(this.cookieName.functional);
          cookiebotAcceptedCookiesList.push(this.cookieName.analytics);
        }

        if (cookiebot.consent.preferences) {
          cookiebotAcceptedCookiesList.push(this.cookieName.other);
        }

        if (cookiebot.consent.marketing) {
          cookiebotAcceptedCookiesList.push(this.cookieName.advertising);
          cookiebotAcceptedCookiesList.push(this.cookieName.social);

          if (this.sFTrackingConsentAcceptUrl) API.get(this.sFTrackingConsentAcceptUrl);
        } else if (this.sFTrackingConsentRejectUrl) {
          API.get(this.sFTrackingConsentRejectUrl);
        }

        this.config.cookies.forEach((cookie) => {
          const value = cookiebotAcceptedCookiesList.includes(cookie.name) ? COOKIE_DEFAULT_VALUE : COOKIE_DECLINED_VALUE;

          this.setCookie(cookie.name, value);
          cookiebotAcceptedCookies[this.prefixCookieName(cookie.name)] = value;
        });

        this.addGlobalCookies(cookiebotAcceptedCookies);
      }, false);
    }
  }

  /**
   * Set global cookies to determine if any form of interaction happened with the cookie bar aso store the date.
   * @param cookieObj {object}
   */
  addGlobalCookies(cookieObj) {
    cookieObj.date = Date(Date.now());
    cookieObj.version = this.config.version;

    this.setCookie(COOKIEBAR_COOKIE_NAME, cookieObj);
    this.setCookie(COOKIEBAR_COOKIE_VERSION, this.config.version);
  }

  /**
   * Pre fill the cookies
   */
  prefillFormCookies() {
    this.form.options.forEach((option) => {
      if (this.getCookie(option.value) === COOKIE_DEFAULT_VALUE) {
        option.setAttribute('checked', 'checked');
      } else {
        option.removeAttribute('checked');
      }
    });
  }

  /**
   * Remove cookies that no longer are valid
   * Revoke SF consent tracking
   */
  removeInvalidatedCookies() {
    this.config.cookies.forEach((cookie) => {
      this.removeCookie(cookie.name);
    });

    this.removeCookie(COOKIEBAR_COOKIE_NAME);
    this.removeCookie(COOKIEBAR_COOKIE_VERSION);

    if (this.sFTrackingConsentRejectUrl) {
      API.get(this.sFTrackingConsentRejectUrl);
    }
  }

  /**
   * Submit cookie settings form
   * Set/revoke SF consent tracking
   * @param event
   */
  submitFormCookies(event) {
    event.preventDefault();

    const acceptedCookies = {};

    this.form.options.forEach((option) => {
      const { value } = option;

      this.config.cookies.forEach((cookie) => {
        if (cookie.name.indexOf(value) !== -1) {
          const state = option.checked ? COOKIE_DEFAULT_VALUE : COOKIE_DECLINED_VALUE;
          this.setCookie(value, state);
          acceptedCookies[this.prefixCookieName(value)] = state;
        }
      });
    });

    if (!this.sFTrackingConsentAcceptUrl || !this.sFTrackingConsentRejectUrl) return;

    API.get(
      this.getCookie(this.cookieName.advertising) === COOKIE_DEFAULT_VALUE
        ? this.sFTrackingConsentAcceptUrl
        : this.sFTrackingConsentRejectUrl,
    ).finally(() => {
      this.addGlobalCookies(acceptedCookies);

      if(this.form.redirectUrl) {
        window.location = this.form.redirectUrl;
      } else {
        window.location = this.form.url;
      }
    });
  }

  /**
   * Set default cookie values
   */
  setDefaultPreferences() {
    this.form.options.forEach((option) => {
      if (this.getCookie(option.value) === COOKIE_DEFAULT_VALUE) {
        option.setAttribute('checked', 'checked');
      } else {
        option.removeAttribute('checked');
      }
    });
  }

  /**
   * Show the cookie bar
   */
  show() {
    if (this.cookiebar) {
      body.classList.add(SHOW_CLASS);
      this.cookiebar.tabIndex = 0;
      setTabIndexOfChildren(this.cookiebar, 0);

      this.cookiebar.focus();
    }
  }

  /**
   * Hide the cookie bar
   */
  hide() {
    if (this.cookiebar) {
      body.classList.remove(SHOW_CLASS);
      setTabIndexOfChildren(this.cookiebar, -1);
      Events.$trigger('cookies::accepted');
    }
  }

  /**
   * Sets cookie with given value
   * @param {String} name
   * @param {any} value
   */
  setCookie(name, value) {
    cookies.set(this.prefixCookieName(name), value, {
      expires: 365,
      domain: `.${this.config.domain}`,
    });
  }

  /**
   * Remove cookie with given value
   * @param {String} name
   */
  removeCookie(name) {
    cookies.remove(this.prefixCookieName(name), { domain: `.${this.config.domain}` });
  }

  /**
   * Checks if cookie is valid and version is correct
   * @returns {Boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  cookieIsValid(name) {
    return this.getCookie(name) === COOKIE_DEFAULT_VALUE;
  }
}

export default new Cookies();
