/* global google */
import MarkerClusterer from '@googlemaps/markerclustererplus'
import array from '@utilities/array'
import Events from '@utilities/events'
import idx from 'idx'

const utilities = {
  map: null, // store map object
  clusterer: null, // store marker clusterer
  markers: {}, // store created marker images
  geocoder: null, // store google maps geocoder
  autocomplete: null, // store google maps places

  /**
   * Return all options required to load a proper map.
   *
   * @returns {Object} options
   */
  getMapOptions(options) {
    const { mapStylingId, zoom, lat, lng } = options
    const latitude = lat || 52.2008737173322
    const longitude = lng || 5.351715087890625

    return {
      mapId: mapStylingId,
      zoom: zoom || 9,
      center: new google.maps.LatLng(latitude, longitude),
      mapTypeControl: false,
      fullscreenControl: true,
      streetViewControl: true,
      scrollwheel: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      panControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    }
  },

  getClustererOptions(icon) {
    return {
      averageCenter: true,
      maxZoom: 7,
      gridSize: 80,
      styles: [
        {
          width: 50,
          height: 50,
          url: icon,
          fontWeight: 'bold',
          textColor: '#ffffff',
          textLineHeight: 50,
          textSize: 16,
        },
      ],
    }
  },

  /**
   * Fit the map to a bounding box containing all provided locations
   * and a given point (search result)
   *
   * @param {Array} locations - array of locations
   * @param {Instance} point - google.maps.LatLng
   */
  setBoundsByLocation(locations, point) {
    let i
    const total = locations.length
    const bounds = new google.maps.LatLngBounds()

    // bounds.extend(point);  // Add the searched location as-well
    const start = point.toJSON()

    for (i = 0; i < total; i++) {
      locations[i].position = new google.maps.LatLng(locations[i].latitude, locations[i].longitude)
      bounds.extend(locations[i].position)
      const originalLocation = locations[i].position.toJSON()
      const newLocation = {}
      // mirror the location to the other side of the point.
      // this way the point get always loaded in the middle and all the stores around it
      newLocation.lat = start.lat + (start.lat - originalLocation.lat)
      newLocation.lng = start.lng + (start.lng - originalLocation.lng)

      const newLatLng = new google.maps.LatLng(newLocation.lat, newLocation.lng)
      bounds.extend(newLatLng)
    }

    this.fitMap(bounds)
    // there is no need do center the map, the mirroring has already bee taking care of it
    // this.center_map(point);
  },

  /**
   * Create the actual Google Map
   *
   * @param {HTMLElement} el - HTML element to render the map
   * @param {Object} options - google.maps.Map options
   */
  createMap(el, options) {
    this.initialOptions = options
    utilities.map = new google.maps.Map(el, options)
    this.onZoomEvent()
    this.onBoundsChanged()
  },

  toRadians(degrees) {
    return (degrees * Math.PI) / 180
  },

  /**
   * Calculate the distance between two points
   * @param {*} lat1
   * @param {*} lon1
   * @param {*} lat2
   * @param {*} lon2
   * @returns - distance in km
   */
  haversine(lat1, lon1, lat2, lon2) {
    const R = 6371000
    const dLat = this.toRadians(lat2 - lat1)
    const dLon = this.toRadians(lon2 - lon1)

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRadians(lat1)) *
        Math.cos(this.toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const distance = (R * c) / 1000 // Distance in km

    return distance
  },

  /**
   * Calculate the radius of the map
   * @param {*} bounds - google.maps.LatLngBounds
   * @returns
   */
  calculateRadius(bounds) {
    const center = bounds.getCenter()
    const ne = bounds.getNorthEast()

    // Using Haversine formula to calculate the distance between center and northeast point
    const radius = this.haversine(center.lat(), center.lng(), ne.lat(), ne.lng())

    return radius
  },

  /**
   * Bind zoom event to the map
   */
  onZoomEvent() {
    google.maps.event.addListener(utilities.map, 'idle', () => {
      const zoomLevel = utilities.map.getZoom()
      const zoomLevelChanged = zoomLevel !== utilities.zoomLevel
      utilities.zoomLevel = zoomLevel

      if (zoomLevelChanged) {
        const bounds = utilities.map.getBounds()
        const center = bounds.getCenter()
        const lat = center.lat()
        const lng = center.lng()
        const radius = this.calculateRadius(bounds)

        Events.$trigger('storelocator:zoom', { data: { lat, lng, radius } })
      }

      if (zoomLevel === 0) {
        utilities.fitBoundsToVisibleMarkers()
      }
    })
  },

  onBoundsChanged() {
    google.maps.event.addListener(utilities.map, 'dragend', () => {
      const bounds = utilities.map.getBounds()
      // get the new lat and log from the center of the map
      const center = bounds.getCenter()
      const lat = center.lat()
      const lng = center.lng()
      const radius = this.calculateRadius(bounds)

      Events.$trigger('storelocator:bounds_changed', {
        data: {
          lat,
          lng,
          radius,
        },
      })
    })
  },

  /**
   * Center map to certain point
   *
   * @param location google.maps.LatLng object
   */
  centerMap(location) {
    utilities.map.panTo(location)
  },

  getCenter() {
    return utilities.map.getCenter()
  },

  /**
   * Set maps zoom level
   *
   * @param zoomlevel
   */
  setZoom(zoomlevel) {
    utilities.map.setZoom(zoomlevel)
  },

  /**
   * Fit map in certain bounding box
   *
   * @param bounds google.maps.Bounds object
   */
  fitMap(bounds) {
    utilities.map.fitBounds(bounds)
  },

  fitBoundsToVisibleMarkers(location) {
    const markers = utilities.clusterer.getMarkers()
    const bounds = new google.maps.LatLngBounds()
    markers.forEach((marker) => bounds.extend(marker.getPosition()))

    if (location) {
      bounds.extend(location)
    }

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01,
      )
      bounds.extend(extendPoint)
    }

    this.fitMap(bounds)
  },
  /**
   * Get geocoder object, for address lookup
   *
   * @returns google.maps.Geocoder
   */
  getGeocoder() {
    if (!utilities.geocoder) {
      utilities.geocoder = new google.maps.Geocoder()
    }

    return utilities.geocoder
  },

  getAutocomplete() {
    const address1Field = document.querySelector(
      '#dwfrm_shipping_shippingAddress_addressFields_address1',
    )
    const houseNumField = document.querySelector(
      '#dwfrm_shipping_shippingAddress_addressFields_streetNo',
    )
    const postalField = document.querySelector(
      '#dwfrm_shipping_shippingAddress_addressFields_postalCode',
    )
    const cityField = document.querySelector('#dwfrm_shipping_shippingAddress_addressFields_city')
    const stateField = document.querySelector('#stateCode')

    const countryField = document.querySelector(
      '[name="dwfrm_shipping_shippingAddress_addressFields_country"]',
    )

    let autocomplete

    // If the autocompleteCache is already instantiated, use it.
    if (utilities.autocomplete && utilities.autocomplete != null && autocomplete !== 'undefined') {
      // eslint-disable-next-line prefer-destructuring
      autocomplete = utilities.autocomplete
    } else {
      autocomplete = new google.maps.places.Autocomplete(address1Field, {
        // eslint-disable-next-line no-undef
        componentRestrictions: { country: [CQuotient.locale.split('_')[1].toLowerCase()] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      })
      // Cache the instantiated object
      utilities.autocomplete = autocomplete
    }

    address1Field.focus()
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', fillInAddress)

    function fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace()
      let address1 = ''
      let postcode = ''
      let houseNum = ''
      let city = ''
      let state = ''

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      place.address_components.forEach((component) => {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0]

        switch (componentType) {
          case 'street_number': {
            houseNum = `${component.long_name}`
            break
          }

          case 'route': {
            address1 = component.long_name
            break
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`
            break
          }

          /*
              case "postal_code_suffix": {
                postcode = `${postcode}-${component.long_name}`;
                break;
              } */

          case 'locality': {
            city = component.long_name
            break
          }

          case 'sublocality_level_1':
          case 'postal_town':
          case 'sublocality': {
            // If 'city' (locality) is not yet assigned, use the sublocality as the city
            if (city === '') {
              city = component.long_name
            }
            break
          }

          case 'administrative_area_level_1': {
            state = component.short_name
            break
          }

          default:
            break
        }
      })

      const event = new Event('input:updateCounter', {
        bubbles: true,
      })

      if (houseNumField) {
        address1Field.value = address1
        const divAddress1Elem = address1Field.closest('[js-hook-lap]')
        divAddress1Elem.classList.add('form__item--lap-active')
        address1Field.dispatchEvent(event)

        houseNumField.value = houseNum
        const divhouseNumElem = houseNumField.closest('[js-hook-lap]')
        divhouseNumElem.classList.add('form__item--lap-active')
        houseNumField.dispatchEvent(event)
      } else {
        const currentCountry = countryField?.value || ''

        address1Field.value =
          currentCountry === 'US' ? `${houseNum} ${address1}` : `${address1} ${houseNum}`

        const divAddress1Elem = address1Field.closest('[js-hook-lap]')
        divAddress1Elem.classList.add('form__item--lap-active')
        address1Field.dispatchEvent(event)
      }

      postalField.value = postcode
      const divPostalElem = postalField.closest('[js-hook-lap]')
      divPostalElem.classList.add('form__item--lap-active')
      postalField.dispatchEvent(event)

      cityField.value = city
      const divCityElem = cityField.closest('[js-hook-lap]')
      divCityElem.classList.add('form__item--lap-active')
      cityField.dispatchEvent(event)

      // let selectedOption = null
      // eslint-disable-next-line no-debugger
      if (stateField) {
        // eslint-disable-next-line prefer-spread
        const stateOptionArray = Array.apply(null, stateField.options)

        const stateIndex = stateOptionArray.findIndex((option) => option.value === state)

        const stateWrapper = stateField ? stateField.closest('.c-select') : null

        const selectedOption = stateWrapper
          ? stateWrapper.querySelector(`.csb__button[data-index='${stateIndex}']`)
          : null

        if (stateWrapper && selectedOption) {
          selectedOption.dispatchEvent(new Event('click'))
        }

        /* eslint-disable no-restricted-syntax */
        /*      for (const option of stateField.options) {
          // eslint-disable-next-line eqeqeq
          if (option.value == state) {
            // Set the 'selected' attribute for the desired option
            option.selected = true
            selectedOption = option
            break // Exit the loop once the option is found
          }
        }

        const stateWrapper = stateField.closest('.select__wrapper')
        if (stateWrapper) {
          const stateLabel = stateWrapper.querySelector('label')
          stateLabel.textContent = selectedOption.text
        }

        stateField.dispatchEvent(new Event('change')) */
      }
    }
  },

  /**
   * Add markers to the map, via a MarkerClusterer object so they will
   * be clustered at certain zoom level
   *
   * @param {Array} markers
   * @param {String} clustererIcon
   */
  addMarkers(markers, clustererIcon) {
    utilities.clusterer = new MarkerClusterer(
      utilities.map,
      markers,
      utilities.getClustererOptions(clustererIcon),
    )
  },

  /**
   * Create the actual marker of `type` with `options`
   *
   * @param {Object} options other options for the marker
   */
  createMarker(options) {
    if (!options.position) {
      throw Error('[Error] Markers need a position')
    }

    if (!(options.position instanceof google.maps.LatLng)) {
      options.position = new google.maps.LatLng(options.position.lat, options.position.lng)
    }

    return new google.maps.Marker(options)
  },

  /**
   * Set map center by user location (navigator.geolocation)
   *
   * @param {Object} position
   */
  setGeolocationCenter(position) {
    const {
      coords: { latitude, longitude },
    } = position
    const coordinates = new google.maps.LatLng(latitude, longitude)
    utilities.centerMap(coordinates)
  },

  /**
   * Use the Google Maps API for calcualting the distance between
   * given point and the current store, this is done with the
   * geometry library which is loaded as an optional requirement
   */
  setDistance(store, point) {
    return (
      google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(store.latitude, store.longitude),
        point,
      ) / 1000
    )
  },

  /**
   * Get all models in this which fit in the bounding box of the map,
   * and sort by distance
   *
   * @param bounds BoundsLatLng (Google Maps API)
   * @param point PointLatLng (Google Maps API)
   * @returns {Array}
   */
  getByBounds(items, bounds, point) {
    return items
      .map((item) => {
        item.distance = this.setDistance(item, point)
        return item
      })
      .filter((item) => bounds.contains(new google.maps.LatLng(item.latitude, item.longitude)))
      .sort((a, b) => a.distance - b.distance)
  },

  /**
   * Get all items by found location, set distance,  set an optional limit of array
   * and sort by distance
   *
   * @param {Array} items array of items
   * @param {Object} found found location
   * @param {Number} limit number
   * @returns {Array}
   */
  getItemsByDistance(items, found, limit) {
    return items
      .map((item) => {
        item.distance = this.setDistance(item, found.location)
        return item
      })
      .sort((a, b) => a.distance - b.distance)
      .splice(0, limit || items.length)
  },

  /**
   * Get all items by city, set distance to false (we don't show distance for city view)
   * and sort by adress2 (street)
   *
   * @param {Array} items array of items
   * @param {String} the name of the city
   * @returns {Array}
   */
  getItemsByCity(items, city) {
    return items
      .map((item) => {
        item.distance = false
        return item
      })
      .filter((item) => item.city.toLowerCase() === city.toLowerCase())
      .sort((a, b) => array.sort(a, b, 'address1'))
  },

  /**
   * Lookup provided address
   *
   * @param {String} address to lookup
   * @param {Function} callback
   */
  lookupAddress(address, callback) {
    callback = callback || function () {} //eslint-disable-line

    const divElement = document.querySelector('div[data-curr-lang]')
    let currLangValue = ''

    if (divElement) {
      currLangValue = divElement.getAttribute('data-curr-lang')
    }

    if (currLangValue == null || currLangValue === 'undefined') {
      currLangValue = 'it'
    }

    this.getGeocoder().geocode(
      {
        address,
        language: currLangValue,
      },
      (results, status) => {
        callback({
          status,
          location: idx(results, (_) => _[0].geometry.location),
          bounds: idx(results, (_) => _[0].geometry.viewport),
        })
      },
    )
  },
}

export default utilities
